:root {
    --color-bg: #34675E;
    --color-bg-variant: #54877E;
    --color-primary: #CAC598;
    --color-primary-variant: #AAA578;
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
}

.portfolio {
  padding:0;
  margin:0;
}

.portfolio-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 15vw;
  width:65vw;
  padding-bottom: 5rem;
}

.portfolio-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 0;
  left: 15vw;
  width: 70vw;
  margin-top: 5rem;
}

.portfolio-mobile h2 {
  position: relative;
  top:-1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.portfolio-mobile h2:before {
  content: '';
  width: 8rem;
  height: 1px;
  background: var(--color-primary);
}

.portfolio-mobile h2:after {
  content: '';
  width: 8rem;
  height: 1px;
  background: var(--color-primary);
}

.portfolio-desktop h2 {
  position: relative;
  top:-1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.portfolio-desktop h2:before {
  content: '';
  width: 10rem;
  height: 1px;
  background: var(--color-primary);
}

.portfolio-desktop h2:after {
  content: '';
  width: 80rem;
  height: 1px;
  background: var(--color-primary);
}

h3 {
  margin-bottom: 2rem;
}

.portfolio-desktop img, .portfolio-mobile img {
    position: relative;
    scale: 1;
    transition: scale 250ms;
    object-fit: cover;
    width: 100%;
    height: 250px;
    border-radius: 15px;
}

.portfolio-desktop img:hover, .portfolio-mobile img:hover {
  scale: 1.1;
  cursor: pointer;
}

.portfolio-mobile img, .portfolio-mobile p {
  margin-bottom: 1rem;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid var(--color-primary-variant);
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  color: var(--color-primary);
  border-radius: 5px 5px 0 0;
  transition: all 400ms ease;
  white-space: nowrap;
  flex: 1;
}

.react-tabs__tab:hover {
  color: var(--color-bg);
  background-color: var(--color-white);
}

.react-tabs__tab--selected {
  background: var(--color-primary);
  border-color: var(--color-primary-variant);
  color: var(--color-bg);
}

.react-tabs__tab--disabled {
  color: var(--color-primary);
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  padding: 1rem;
}

@media screen and (max-width:600px) {
    .portfolio-desktop{
      display: none;
    }
    .portfolio-mobile {
      display: block;
    }
}

@media screen and (min-width:601px) {
  .portfolio-desktop{
    display: block;
  }
  .portfolio-mobile {
    display: none;
  }
}

.portfolio-text {
  font-size: 0.8em;
}

/*
  Blog/Wiki
*/

.frame__container p {
  width: 75%;
}

.blog__frame {
  width: 100%;
  height: 500px;
}
  

/*
  eCommerce
*/

.ecommerce__container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.ecommerce__container > * {
  width: 33%;
}

/*
  Gallery
*/

.gallery__container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.gallery__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.gallery__row a {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery__row a img {
  filter: opacity(0.8) grayscale(1);
  max-width: 200px;
}

.gallery__row a img:hover {
  filter: opacity(1) grayscale(0);
}

.gallery__row a img:hover + label {
  opacity: 0;
}

.gallery__row a label {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
  background-color: #34675EBB;
  padding: 1rem;
  border-radius: 20px;
  font-weight: bold;
  color: #fff;
  border: 5px solid #CAC598;
  opacity: 1;
  transition: opacity 500ms;
}

.gallery__row p {
  width: 33%;
}

@media screen and (max-width:800px){
  .gallery__row {
    flex-direction: column;
  }

  .gallery__row p {
    width: unset;
  }
}